import store from "../store";

export default {
    methods: {
        async processWithLoading(callback){
            const AppLoadingTimeoutHandler = setTimeout(() => {
                store.commit('UPDATE_IS_SHOW_APP_LOADING', true);
            }, 500);

            await callback();

            clearTimeout(AppLoadingTimeoutHandler);
            store.commit('UPDATE_IS_SHOW_APP_LOADING', false);
        },
    }
};
